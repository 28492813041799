<template>
  <Editor
    :value="mdValue"
    :mode="mode"
    :plugins="plugins"
    @change="handleChange"
  />
</template>

<script setup lang="ts">
import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import { ref, withDefaults, defineProps } from "vue";
import { Editor, Viewer } from "@bytemd/vue-next";

interface Props {
  mdValue: string;
  mode: string;
  handleChange: (v: string) => void;
}

const plugins = [gfm(), highlight()];

// const handleChange = (v: string) => {
//   mdValue.value = v;
// };

// const props = defineProps({
//   mdValue: String,
//   handleChange: Function,
// });

// const props = defineProps<Props>();

const props = withDefaults(defineProps<Props>(), {
  mdValue: () => "",
  mode: () => "split",
  handleChange: (v: string) => {
    console.log(v);
  },
});
</script>

<style scoped></style>
