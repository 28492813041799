<template>
  <div class="userLoginView">
    <a-form
      class="form-first"
      layout="vertical"
      :model="form"
      @submit="handleSubmit"
    >
      <a-form-item field="userAccount">
        <a-input v-model="form.userAccount" placeholder="请输入用户名">
          <template #prepend>
            <icon-user />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item field="userPassword">
        <a-input-password v-model="form.userPassword" placeholder="请输入密码">
          <template #prepend>
            <icon-lock />
          </template>
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-button long html-type="submit" type="outline" shape="round"
          >登陆
        </a-button>
      </a-form-item>
      <a-form-item class="qqLogin" field="qqLogin">
        其他登陆方式 &nbsp;
        <a-button id="qqLoginBtn" shape="circle" @click="handleQqLogin">
          <icon-qq-circle-fill size="large" />
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import { UserControllerService } from "../../../generated";
import { useRouter } from "vue-router";
import { Message } from "@arco-design/web-vue";
import { useStore } from "vuex";

const router = useRouter();
const store1 = useStore();

const form = reactive({
  userAccount: "",
  userPassword: "",
});

/**
 * 普通登陆
 */
const handleSubmit = async () => {
  const res = await UserControllerService.userLoginUsingPost(form);
  console.log(res);
  if (res.code === 0) {
    // await store.dispatch("getLoginUser");
    await store1.dispatch("getLoginUser");
    await router.push({
      path: "/",
      replace: true,
    });
  } else {
    Message.error("登陆失败");
  }
};

/**
 * QQ登陆
 */
const handleQqLogin = () => {
  console.log(window.QC.Login.check());
  console.log(window.QC);
  if (!window.QC.Login.check()) {
    window.QC.Login.showPopup({
      appId: "102083502",
      redirectURL: "http://localhost:8081/login/callback",
    });
  }
};

const openId = ref("");
const isBind = ref(false);
// const handleQqLogin = () => {
//   console.log(window.QC.Login.check());
//   console.log(window.QC);
//   if (window.QC.Login.check()) {
//     // 代表QQ已经登录过
//     window.QC.Login.getMe((id) => {
//       console.log("openId", id);
//       // 登录储存id
//       openId.value = id;
//       //       // 进行登录
//       // loginByQQ(id).then((res) => {
//       //   console.log(res);
//       //   if (res.message == "未绑定手机，请绑定手机号") {
//       //     console.log("先绑定手机号再登录");
//       //     isBind.value = true;
//       //     return;
//       //   }
//       //   // 否则登录成功
//       //   loginSuccess(res.data);
//       //   console.log("登录成功");
//       // });
//     });
//   }
// };

const loginSuccess = (item: any) => {
  console.log("登陆成功", item);
};
</script>

<style scoped>
.qqLogin {
  font-size: 14px;
}

.form-first {
  min-width: 100%;
  max-width: 480px;
}
</style>
