import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ACCESS_ENUM from "@/access/accessEnums";
import checkAccess from "@/access/checkAccess";
import store from "@/store";
import router from "@/router";
import { computed } from "vue";

/**
 * 动态获取用户信息
 */
const loginUser = computed(() => {
  return store.state.user?.loginUser;
});

/**
 * 控制隐藏页面、根据页面路由的meta进行页面控制
 */
router.beforeEach(async (to, from, next) => {
  let currUser = loginUser.value;
  let currRole = currUser?.userRole;
  console.log("curr## ", currRole, " ##to## ", to);

  // 判断是否登陆
  if (!currUser || !currRole) {
    // 等获取当前用户信息后执行后续代码
    try {
      await store.dispatch("getLoginUser");
    } catch (e) {
      console.log("获取信息失败");
    }
    currUser = loginUser.value;
    currRole = currUser?.userRole ?? ACCESS_ENUM.NOT_LOGIN;
  }

  const needRole = to.meta?.access ?? ACCESS_ENUM.NOT_LOGIN;
  console.log("need==", needRole, " ##curr## ", currRole, " ##to## ", to);
  // 判断用户是否需要登陆, 用户需要跳到登陆页面逻辑：1-当前路由需要登陆 并且 2-当前用户角色为空 3-当前角色为未登陆
  if (
    needRole !== ACCESS_ENUM.NOT_LOGIN &&
    currRole === ACCESS_ENUM.NOT_LOGIN
  ) {
    next("/user/login");
  }

  // 判断当前用户是否有权限进入
  if (!checkAccess(currUser, needRole as string)) {
    next("/noAuth");
  }
  next();
});
