<template>
  <div id="addQuestionView">
    <h2 style="text-align: center">创建题目</h2>

    <a-form :model="form" style="max-width: 80%">
      <a-form-item field="title" label="标题">
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-item>

      <a-form-item field="title" label="标签">
        <a-input-tag v-model="form.tags" placeholder="请输入标签" allow-clear />
      </a-form-item>

      <a-form-item field="form.content" label="题目内容">
        <MdEditor
          :md-value="form.content"
          :handle-change="handleChangeContent"
        />
      </a-form-item>

      <a-form-item field="form.answer" label="答案">
        <MdEditor :md-value="form.answer" :handle-change="handleChangeAnswer" />
      </a-form-item>

      <a-form-item label="限制" :content-flex="false" :merge-props="false">
        <a-space direction="vertical" style="min-width: 400px">
          <a-form-item field="judgeConfig.memoryLimit" label="内存限制">
            <a-input-number
              model-value="form.judgeConfig.memoryLimit"
              placeholder="输入内存限制"
              mode="button"
              size="large"
              min="0"
            />
          </a-form-item>
          <a-form-item field="judgeConfig.timeLimit" label="时间限制">
            <a-input-number
              v-model="form.judgeConfig.timeLimit"
              placeholder="输入时间限制"
              mode="button"
              size="large"
            />
          </a-form-item>
          <a-form-item field="judgeConfig.stackLimit" label="堆栈限制">
            <a-input-number
              v-model="form.judgeConfig.stackLimit"
              placeholder="输入堆栈限制"
              mode="button"
              size="large"
            />
            {{ form.judgeConfig.stackLimit }}
          </a-form-item>
        </a-space>
      </a-form-item>

      <a-form-item label="测试用例配置" :content-flex="false">
        <a-space
          v-for="(judgeCaseItem, index) of form.judgeCase"
          :key="index"
          align="start"
          justify="start"
        >
          <a-form-item
            :field="`form.judgeCase[${index}].input`"
            :label="`输入用例${index}`"
            style="min-width: 400px"
          >
            <a-input v-model="judgeCaseItem.input" placeholder="输入用例" />
          </a-form-item>
          <a-form-item
            :field="`form.judgeCase[${index}].output`"
            :label="`输出用例${index}`"
            style="min-width: 400px"
          >
            <a-input v-model="judgeCaseItem.output" placeholder="输出用例" />
          </a-form-item>
          <a-button
            status="danger"
            @click="handleDeleteJudgeCase(index)"
            :style="{ marginLeft: '10px' }"
            >Delete
          </a-button>
        </a-space>
        <div>
          <a-button status="success" @click="handleAddJudgeCase"
            >新增用例
          </a-button>
        </div>
      </a-form-item>

      <a-form-item>
        <a-button @click="doSubmit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import MdEditor from "@/components/MdEditor.vue";
import { QuestionControllerService } from "../../../generated";
import { Message } from "@arco-design/web-vue";
import { useRoute } from "vue-router";

const form = ref({
  title: "",
  tags: [],
  answer: "",
  content: "",
  judgeConfig: {
    memoryLimit: 0,
    stackLimit: 0,
    timeLimit: 0,
  },
  judgeCase: [
    {
      input: "",
      output: "",
    },
  ],
});

const route = useRoute();
// 根据路由判断是修改还是新增
const updatePage = route.path.includes("update");

const loadData = async () => {
  const id = route.query.id;
  if (!id) {
    return;
  }
  const editData = await QuestionControllerService.getQuestionEditByIdUsingGet(
    id as any
  );
  if (editData.code === 0) {
    form.value = editData.data as any;
    console.log("加载数据：", editData);
    console.log("加载数据：", form);
    if (!editData.data?.judgeCase) {
      form.value.judgeCase = [];
    }
    if (editData.data?.judgeConfig) {
      form.value.judgeConfig = {
        memoryLimit: editData.data?.judgeConfig.memoryLimit as number,
        stackLimit: editData.data?.judgeConfig.stackLimit as number,
        timeLimit: editData.data?.judgeConfig.timeLimit as number,
      };
    } else {
      form.value.judgeConfig = {
        memoryLimit: 1,
        stackLimit: 1,
        timeLimit: 1,
      };
    }
  } else {
    Message.error("数据加载失败" + editData.message);
  }
};

onMounted(() => {
  loadData();
});

const handleDeleteJudgeCase = (i: number) => {
  form.value.judgeCase.splice(i, 1);
};

const handleAddJudgeCase = () => {
  form.value.judgeCase.push({
    input: "",
    output: "",
  });
};

const doSubmit = async () => {
  if (updatePage) {
    // 修改
    const res = await QuestionControllerService.updateQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      Message.success("修改成功");
    } else {
      Message.error("修改失败" + res.message);
    }
  } else {
    // 新增
    const res = await QuestionControllerService.addQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      Message.success("保存成功");
    } else {
      Message.error("保存失败" + res.message);
    }
  }
};

const handleChangeContent = (v: string) => {
  form.value.content = v;
};

const handleChangeAnswer = (v: string) => {
  form.value.answer = v;
};
</script>

<style>
#addQuestionView {
}

a-input {
  max-width: 50px;
}
</style>
