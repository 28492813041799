import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AboutView from "../views/AdminView.vue";
import AdminView from "../views/AdminView.vue";
import HomeView from "../views/ExampleView.vue";
import NoAuthView from "../views/NoAuthView.vue";
import AddQuestionView from "../views/question/AddQuestionView.vue";
import ManageQuestionView from "../views/question/ManageQuestionView.vue";
import UserLoginView from "../views/user/UserLoginView.vue";
import FourView from "../views/404View.vue";
import helloWorld from "@/components/HelloWorld.vue";
import ACCESS_ENUM from "@/access/accessEnums";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/user",
    name: "用户",
    children: [
      {
        path: "/user/login",
        name: "登陆",
        component: UserLoginView,
      },
    ],
    redirect: "/user/login",
    meta: {
      hidden: true,
    },
  },
  {
    path: "/add/question",
    name: "创建题目",
    component: AddQuestionView,
    meta: {
      access: ACCESS_ENUM.ADMIN,
    },
  },
  {
    path: "/update/question",
    name: "更新题目",
    component: AddQuestionView,
    meta: {
      access: ACCESS_ENUM.ADMIN,
      hidden: true,
    },
  },
  {
    path: "/manage/question/",
    name: "管理题目",
    component: ManageQuestionView,
    meta: {
      access: ACCESS_ENUM.ADMIN,
    },
  },
  {
    path: "/",
    name: "首页",
    component: HomeView,
    meta: {
      access: ACCESS_ENUM.USER,
    },
  },
  {
    path: "/about",
    name: "关于我",
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    component: AboutView,
    meta: {
      access: ACCESS_ENUM.USER,
    },
  },
  {
    path: "/hello",
    name: "你好",
    component: helloWorld,
    meta: {
      access: ACCESS_ENUM.USER,
    },
  },
  {
    path: "/noAuth",
    name: "无权限",
    component: NoAuthView,
    meta: {
      hidden: true,
      access: ACCESS_ENUM.NOT_LOGIN,
    },
  },
  {
    path: "/admin",
    name: "管理员",
    component: AdminView,
    meta: {
      access: ACCESS_ENUM.ADMIN,
    },
  },
  {
    path: "/404",
    name: "404",
    component: FourView,
    meta: {
      hidden: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "other",
    redirect: "/user/login",
    meta: {
      hidden: true,
    },
  },
];
