/**
 * 判断当前用户是否有权限
 */
import ACCESS_ENUM from "@/access/accessEnums";

/**
 * 判断当前路由是否符合用户权限
 * @param loginUser 当前用户
 * @param needAccess 当前路由需要的权限
 */
const checkAccess = (loginUser: any, needAccess = ACCESS_ENUM.NOT_LOGIN) => {
  const loginUSerAccess = loginUser?.userRole ?? ACCESS_ENUM.NOT_LOGIN;
  // 不需要权限
  if (needAccess === ACCESS_ENUM.NOT_LOGIN) {
    return true;
  }

  // 需要登陆
  if (
    needAccess === ACCESS_ENUM.USER &&
    loginUSerAccess === ACCESS_ENUM.NOT_LOGIN
  ) {
    return false;
  }

  // 需要管理员权限
  if (
    needAccess === ACCESS_ENUM.ADMIN &&
    loginUSerAccess !== ACCESS_ENUM.ADMIN
  ) {
    return false;
  }

  return true;
};

export default checkAccess;
