<template>
  <div id="code-editor" ref="codeEditorRef" style="min-height: 400px" />
</template>

<script setup lang="ts">
import { onMounted, ref, toRaw, withDefaults, defineProps } from "vue";
import * as monaco from "monaco-editor";

const codeEditorRef = ref();
const codeEditor = ref();

interface Props {
  codeValue: string;
  handleChange: (v: string) => void;
}

const props = withDefaults(defineProps<Props>(), {
  codeValue: () => "hello new year",
  handleChange: (v: string) => {
    console.log(v);
  },
});

onMounted(() => {
  if (!codeEditorRef.value) {
    return;
  }

  codeEditor.value = monaco.editor.create(codeEditorRef.value, {
    value: props.codeValue,
    language: "java",
    automaticLayout: true,
    colorDecorators: true,
    minimap: {
      enabled: true,
    },
    // readOnly: true,
    theme: "vs-dark",
  });

  // 监听内容变化
  codeEditor.value.onDidChangeModelContent(() => {
    console.log(toRaw(codeEditor.value).getValue());
    props.handleChange(toRaw(codeEditor.value).getValue());
  });
});
</script>

<style scoped></style>
