<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <MdEditor :md-value="mdValue" :handle-change="handleChange" />
    <CodeEditor :md-value="codeValue" :handle-change="handleChange" />
    {{ codeValue }}
  </div>
</template>

<script setup lang="ts">
import MdEditor from "@/components/MdEditor.vue";
import { ref, watch } from "vue";
import CodeEditor from "@/components/CodeEditor.vue"; // @ is an alias to /src

const mdValue = ref("");
const codeValue = ref("");

const handleChange = (v: string) => {
  mdValue.value = v;
  codeValue.value = v;
};
//
// watch(mdValue, (v) => {
//   console.log(`new count is: ${v}`);
// });
</script>
