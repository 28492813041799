<template>
  <div class="footer">
    <div class="footer-first">
      <a
        class="ant-pro-global-footer-list-link"
        title="小韩"
        target="_blank"
        href="https://hstudent.xyz/"
        >梦幻の风</a
      >
      <a
        class="ant-pro-global-footer-list-link"
        title="github"
        target="_blank"
        href="https://github.com/aDreamyWind"
      >
        <icon-github />
      </a>
      <a target="_blank" rel="noreferrer" href="https://beian.miit.gov.cn/">
        浙 ICP 备 2023012151 号 - 1
      </a>
    </div>

    <div class="footer-name">
      <icon-copyright />
      2023 小韩很有钱
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
a {
  target: "_blank";
  rel: "noreferrer";
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
}

.footer {
  /*background: antiquewhite;*/
  padding: 16px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.footer-first {
  margin-block-end: 8px;
  color: rgba(0, 0, 0, 0.65);
  /*margin-inline-end: 8px;*/
}

.ant-pro-global-footer-list-link {
  margin-inline-end: 8px;
}

.footer-name {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
}
</style>
