<template>
  <a-row class="grid-demo" id="globalHeader" align="center" :wrap="false">
    <a-col flex="auto">
      <a-menu
        mode="horizontal"
        :selected-keys="selectedKeys"
        @menu-item-click="doMenuClick"
      >
        <a-menu-item
          key="0"
          :style="{ padding: 0, marginRight: '38px' }"
          disabled
        >
          <div class="title-bar">
            <img class="logo" src="../assets/logo.png" />
            <div class="title">九魈</div>
          </div>
        </a-menu-item>
        <a-menu-item v-for="item in visibleRoutes" :key="item.path"
          >{{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col flex="100px">
      <div>{{ userStore?.loginUser?.userName ?? "未登陆" }}</div>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { routes } from "@/router/routes";
import { useRoute, useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import checkAccess from "@/access/checkAccess";

// 获取路由
const router = useRouter();
const route = useRoute();
// 获取全局变量
const store = useStore();
// 获取用户信息
const userStore = store.state.user;
// 默认主页
const selectedKeys = ref(["/"]);

// 点击菜单项时触发切换路由
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};

// 路由切换时保存当前路由地址
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});

// 展示所以可以看的菜单
const visibleRoutes = computed(() => {
  return routes.filter((item) => {
    if (item?.meta?.hidden) return false;
    return checkAccess(userStore?.loginUser, item?.meta?.access as string);
  });
});
// 控制隐藏页面、根据页面路由的meta进行页面控制
router.beforeEach((to, from, next) => {
  // 判断当前用户是否有权限进入
  if (to.meta?.hideInMenu && userStore?.loginUser?.userName !== "admin") {
    next("/no");
    return;
  }
  next();
});

// 调用全局变量的方法
// store.dispatch("getLoginUser", {
//   userName: "vhasd",
// });
</script>

<style scoped>
.title-bar {
  align-items: center;
  display: flex;
}

.logo {
  height: 50px;
}

.title {
  color: #444;
  margin-left: 14px;
}
</style>
