<template>
  <div id="manageQuestionView">
    <h2 style="text-align: center">管理题目</h2>
    <a-table
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParam.pageSize,
        current: searchParam.current,
        total,
      }"
      @pageChange="pageLoad"
    >
      <template #optional="{ record }">
        <a-button type="primary" @click="doUpdate(record)">修改</a-button>
        <br />
        <a-button status="danger" @click="doDelete(record)">删除</a-button>
      </template>
    </a-table>
    <!--    弹出对话框     -->
    <!--    <a-modal-->
    <!--      v-model:visible="show"-->
    <!--      title="修改题目"-->
    <!--      @cancel="handleCancel"-->
    <!--      @before-ok="handleBeforeOk"-->
    <!--    >-->
    <!--      <QuestionComponent :form-data="form" :handle-sub="doUpdate" />-->
    <!--    </a-modal>-->
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import {
  Question,
  QuestionControllerService,
  QuestionQueryRequest,
} from "../../../generated";
import { Message } from "@arco-design/web-vue";
import { useRouter } from "vue-router";

const show = ref(false);
const form = ref<Question>();
const total = ref(0);

const dataList = ref([]);
const searchParam = ref<QuestionQueryRequest>({
  current: 1,
  pageSize: 3,
});

const pageLoad = (pageNum: number) => {
  searchParam.value.current = pageNum;
  loadData();
};

const loadData = async () => {
  const res = await QuestionControllerService.listQuestionByPageUsingPost(
    searchParam.value
  );
  if (res.code === 0) {
    // console.log("res: ", res);
    dataList.value = res.data?.records;
    total.value = res.data.total;
  } else {
    Message.error("获取失败" + res.message);
  }
};

const doDelete = async (record: Question) => {
  const res = await QuestionControllerService.deleteQuestionUsingPost({
    id: record.id,
  });
  if (res.code === 0) {
    Message.success("删除成功");
    await loadData();
  } else {
    Message.success("删除失败");
  }
};

const router = useRouter();

const doUpdate = (record: Question) => {
  router.push({
    path: "/update/question",
    query: {
      id: record.id,
    },
  });
};

// // 弹出窗口的修改
// const doUpdate = () => {
//   console.log("当前表单数据", form.value);
// };
//
// // 打开隐藏的对话框
// const handleShow = (record: Question) => {
//   show.value = true;
//   form.value = record;
// };
//
// // 对话框确认
// const handleBeforeOk = (done: any) => {
//   console.log("done", done);
//   window.setTimeout(() => {
//     done();
//   }, 3000);
// };
//
// // 对话框取消
// const handleCancel = () => {
//   show.value = false;
// };

onMounted(() => {
  loadData();
});

const columns = [
  {
    title: "id",
    dataIndex: "id",
  },
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "内容",
    dataIndex: "content",
  },
  {
    title: "标签",
    dataIndex: "tags",
  },
  {
    title: "答案",
    dataIndex: "answer",
  },
  {
    title: "提交数",
    dataIndex: "submitNum",
  },
  {
    title: "通过数",
    dataIndex: "acceptedNum",
  },
  {
    title: "判题用例",
    dataIndex: "judgeCase",
  },
  {
    title: "判题配置",
    dataIndex: "judgeConfig",
  },
  {
    title: "用户id",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];
</script>

<style>
#manageQuestionView {
}
</style>
